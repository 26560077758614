.pulse {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 20px;
    text-align: center;
    width: 100%;
    max-width: 350px;
    border-radius: 10px;
    background: $primary;
    border: 2px solid $primary;
    animation: pulse 2s infinite;
    transition: all 0.3s ease-in-out;
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(45, 120, 247, 0.4);
    }
    100% {
        box-shadow: 0 0 0 25px rgba(45, 120, 247, 0);
    }
}
.chakra-radio__control[data-checked] {
    background-color: $primary !important;
    border-color: $primary !important;
}
.center-item {
    margin: 4rem 0;
    text-align: center;
    @media only screen and (max-width: $small) {
        margin: 2rem 0;
    }
}
@media only screen and (max-width: $medium) {
    .main-contianer .css-14xmlw6 {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
    }
    .main-contianer .css-x4kbrg {
        padding: 1rem 0;
    }
}

.css-isk0hu:focus,
.css-isk0hu[data-focus] {
    box-shadow: none !important;
}

input:focus {
    outline: $primary;
    border-color: $primary !important;
    box-shadow: none !important;
}

.fill-primary {
    fill: $primary;
}
.stroke-primary {
    stroke: $primary;
}
.fill-secondary {
    fill: $secondary;
}

// Temp here

.text-icon-conatiner {
    .icon-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        & div {
            &:nth-child(1) {
                width: 30px;
                margin-right: 1rem;
                svg {
                    color: $primary;
                }
            }
            &:nth-child(2) {
                width: calc(100% - 50px);
            }
        }
    }
}

.header-container {
    background-position: 100% 100%;
    background-image: url(../assets/img/header.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

